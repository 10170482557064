export enum EErrorMessage {
    NOT_FOUND_DATA = 'NOT_FOUND_DATA',
    NOT_COLLECT_DATA_FOUND = 'NOT_COLLECT_DATA_FOUND',
    NOT_DOCUMENT_DATA_FOUND = 'NOT_DOCUMENT_DATA_FOUND',

    UPDATE_DOCUMENT_ERROR = 'UPDATE_DOCUMENT_ERROR',

    MISSING_ID = 'MISSING_ID',
    MISSING_DATA = 'MISSING_DATA',
    UNAUTHORIZED = 'UNAUTHORIZED',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR',
    GET_DOCUMENT_ERROR = 'GET_DOCUMENT_ERROR',
    GET_COLLECTION_ERROR = 'GET_COLLECTION_ERROR',
    DELETE_DOCUMENT_ERROR = 'DELETE_DOCUMENT_ERROR',
}