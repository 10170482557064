import React, { useCallback, useEffect, useState } from 'react'

// Icons

import iconRight from "../../image/ico-next.png";
import iconLeft from "../../image/ico-prev.png";
import { Button, Col, Row, Typography } from 'antd';
import { Content } from 'antd/es/layout/layout';
import ButtonAccept from './ButtonAccept';
import { Job } from '../../types/position';
import { PreCandidateInterface } from '../../models/preCandidate.interface';
import User from '../../types/user';
import ButtonDecline from './ButtonDecline';
import SkillSectionCandidateCard from '../candidateCard/SkillSectionCandidateCard';
import { Timestamp } from 'firebase/firestore';
import EducationSectionCandidateCard from '../candidateCard/EducationSectionCandidateCard';
import ExperienceSectionCandidateCard from './ExperienceSectionCandidateCard';
import './PreCandidateCard.css'
import { useSelector } from 'react-redux';

// Interface
interface Props {
    prev: () => void;
    next: () => void;
    position: Job;
    preCandidate: PreCandidateInterface;
    user: User
}
const PreCandidateCard: React.FC<Props> = ({ prev, next, position, preCandidate, user }) => {
    const [loadingOnClickButton, setLoadingOnClickButton] = useState<boolean>(false);
    // States
    const [experience, setExperience] = useState<Array<any>>([]);
    const [education, setEducation] = useState<Array<any>>([]);
    const [skill, setSkill] = useState<Array<any>>([]);

    const setValues = useCallback(
        () => {
            preCandidate.skills && setSkill(preCandidate.skills);
            const experiences: Array<any> = [];
            const education: Array<any> = [];

            preCandidate.experience?.forEach((experienceData: any) => {
                const startFullYear = new Timestamp(
                    experienceData.dateStart.seconds,
                    experienceData.dateStart.nanoseconds
                )
                    .toDate()
                    .getFullYear();

                if (experienceData.actualPosition) {
                    experiences.push({
                        ...experienceData,
                        dateStart: startFullYear,
                        dateEnd: Timestamp.now().toDate().getFullYear(),
                        dateEndComplete: Timestamp.now().seconds,
                    });
                } else {
                    const endFullYear = new Timestamp(
                        experienceData.dateEnd.seconds,
                        experienceData.dateEnd.nanoseconds
                    )
                        .toDate()
                        .getFullYear();

                    experiences.push({
                        ...experienceData,
                        dateStart: startFullYear,
                        dateEnd: endFullYear,
                        dateEndComplete: experienceData.dateEnd.seconds,
                    });
                }
            });

            preCandidate.education?.forEach((educationData: any) => {
                if (educationData.graduationDate) {
                    const graduationDate = new Timestamp(educationData.graduationDate.seconds, educationData.graduationDate.nanoseconds).toDate().getFullYear();

                    education.push({
                        ...educationData,
                        dateGraduationDateOk: graduationDate,
                    });
                } else {
                    education.push({
                        ...educationData,
                        dateGraduationDateOk: new Date(),
                    });
                }
            });

            setExperience(
                experiences.sort((a, b) => b.dateEndComplete - a.dateEndComplete)
            );
            setEducation(
                education.sort(
                    (a, b) => b.dateGraduationDateOk - a.dateGraduationDateOk
                )
            );
        },
        [preCandidate.skills, preCandidate.education, preCandidate.experience],
    )


    // Effects
    useEffect(() => {

        setValues()

        return () => {

        }
    }, [setValues])
    const auth = useSelector((state: any) => state.auth.user);
    return (
        <Content className='content-pre-candidate-card'>
            {(auth.idHiring === 'SLUJ8Q9u4Sg0ms501LxmkrIBVQs2' || auth.idHiring === 'Zc0XfBuSlPTg0baD9AUpD7IwOZd2') && (
                <div
                    style={{
                        display: 'flex',
                        position: 'absolute',
                        top: 10,
                        left: 10,
                        zIndex: 1,
                        background: 'linear-gradient(16deg, rgb(96, 82, 163), rgb(50, 45, 90))',
                        boxSizing: 'border-box',
                        paddingLeft: 15,
                        paddingRight: 15,
                        borderRadius: '20px'
                    }}
                >
                    <Typography.Text style={{ color: 'white', fontSize: 20, fontWeight: 'bold' }}>
                        Pre Candidate
                    </Typography.Text>
                </div>
            )}

            <Row className='row-experience-pre-candidate-card'>
                <Col className='col-experience-pre-candidate-card' xs={24} sm={24} md={24} lg={24} xl={{ span: 12 }} xxl={13}>
                    <ExperienceSectionCandidateCard experience={experience} />
                </Col>
                <Col className='col-education-pre-candidate-card' xs={24} sm={24} md={24} lg={24} xl={{ span: 12 }} xxl={11}>
                    <EducationSectionCandidateCard education={education} />
                </Col>
            </Row>

            <Row className='row-skills-pre-candidate-card'>
                <Col xs={24}>
                    <SkillSectionCandidateCard skill={skill} />
                </Col>
            </Row>

            <Row className='row-buttons'>
                <Col xs={24} sm={12} md={6} lg={6} xxl={6}
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        paddingLeft: 50,
                    }}
                >
                    <Button
                        disabled={loadingOnClickButton}
                        onClick={prev}
                        style={{
                            borderColor: "transparent",
                            height: 40,
                            width: 40,
                            opacity: loadingOnClickButton ? 0.4 : 1,
                        }}
                        icon={
                            <img
                                src={iconLeft}
                                style={{ height: 40, width: 40, opacity: 0.5 }}
                                alt={iconLeft}
                            />
                        }
                    />
                </Col>

                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}
                    xxl={6}
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ButtonAccept loading={loadingOnClickButton} position={position} preCandidate={preCandidate} setLoading={setLoadingOnClickButton} user={user} />
                </Col>

                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}
                    xxl={6}
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}
                >
                    <ButtonDecline loading={loadingOnClickButton} position={position} preCandidate={preCandidate} setLoading={setLoadingOnClickButton} user={user} />
                </Col>

                <Col
                    xs={24}
                    sm={12}
                    md={6}
                    lg={6}
                    xxl={6}
                    style={{
                        height: "100%",
                        display: "flex",
                        justifyContent: "flex-end",
                        alignItems: "center",
                        paddingRight: 50,
                    }}
                >
                    <Button
                        disabled={loadingOnClickButton}
                        onClick={next}
                        style={{
                            borderColor: "transparent",
                            height: 40,
                            width: 40,
                            opacity: loadingOnClickButton ? 0.4 : 1,
                        }}
                        icon={
                            <img
                                src={iconRight}
                                style={{ height: 40, width: 40, opacity: 0.7 }}
                                alt={iconRight}
                            />
                        }
                    />
                </Col>
            </Row>
        </Content>
    )
}

export default PreCandidateCard
