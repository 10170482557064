import React, { useEffect, useRef, useState } from "react";
import { db } from "../../firebase/config";
import { useSelector } from "react-redux";
import { collection, onSnapshot, query, where } from "firebase/firestore";
import { Typography, Carousel } from "antd";
import { Content } from "antd/lib/layout/layout";

import CandidateCard from "../../common/candidateCard/CandidateCard";
import PreCandidateCard from "../../common/PreCandidateCard/PreCandidateCard";
import LoadingCard from "./LoadingCard";
import SourcingLoading from "./SourcingLoading";

import { Candidate } from "../../types/candidate";
import { Job } from "../../types/position";
import { PreCandidateInterface } from "../../models/preCandidate.interface";
import { getCandidateDocument } from "../../firebase/services/candidatesCollect/candidate";
import { getPreCandidateDocument } from "../../firebase/controllers/PreCandidate.services.firebase";

const { Text } = Typography;

interface Props {
  position: Job;
}

const CandidateList: React.FC<Props> = ({ position }) => {
  const [candidates, setCandidates] = useState<Candidate[]>([]);
  const [preCandidates, setPreCandidates] = useState<PreCandidateInterface[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const [currentIndex, setCurrentIndex] = useState(0);

  const user = useSelector((state: any) => state.auth.user);
  const { dictionary } = useSelector((state: any) => state.ui);

  const carouselRef = useRef<any>(null);

  // Obtener candidatos recomendados y pre-candidatos
  useEffect(() => {
    if (!user?.idHiring || !position?.idPosition) return;

    setLoading(true);

    const candidatesRef = collection(db, `hirings/${user.idHiring}/positions/${position.idPosition}/recommendationCandidates`);
    const preCandidatesRef = collection(db, `hirings/${user.idHiring}/positions/${position.idPosition}/recommendationPreCandidates`);

    const candidatesQuery = query(candidatesRef, where("like", "==", ""));
    const preCandidatesQuery = query(preCandidatesRef, where("hiringLike", "==", null));

    const unsubscribeCandidates = onSnapshot(candidatesQuery, async (snapshot) => {
      const fetchedCandidates: Candidate[] = [];
      for (const doc of snapshot.docs) {
        const getCandidateDocumentResponse = await getCandidateDocument(doc.data().id);

        if (getCandidateDocumentResponse.success && getCandidateDocumentResponse.data) {
          fetchedCandidates.push(getCandidateDocumentResponse.data);
        }

      }
      setCandidates(fetchedCandidates);
    });

    const unsubscribePreCandidates = onSnapshot(preCandidatesQuery, async (snapshot) => {
      const fetchedPreCandidates: PreCandidateInterface[] = [];
      for (const doc of snapshot.docs) {
        const { success, data } = await getPreCandidateDocument(doc.data().id);
        if (success && data) fetchedPreCandidates.push(data);
      }
      setPreCandidates(fetchedPreCandidates);
      setLoading(false);
    });

    return () => {
      unsubscribeCandidates();
      unsubscribePreCandidates();
    };
  }, [user?.idHiring, position?.idPosition]);

  // Lista combinada
  const combinedList = [...candidates, ...preCandidates];
  const totalCount = combinedList.length;

  // Navegación en el carrusel
  const next = () => {
    if (currentIndex < totalCount - 1) {
      setCurrentIndex((prev) => prev + 1);
      carouselRef.current?.next();
    }
  };

  const prev = () => {
    if (currentIndex > 0) {
      setCurrentIndex((prev) => prev - 1);
      carouselRef.current?.prev();
    }
  };
  useEffect(() => {


    return () => {
      setCandidates([]);
      setPreCandidates([]);
      setLoading(true);
      setCurrentIndex(0);
    }
  }, [])


  return (
    <Content style={{ height: "100%", width: "100%", position: "relative" }}>
      {loading ? (
        <LoadingCard />
      ) : totalCount > 0 ? (
        <>
          <Carousel ref={carouselRef} dots={false} style={{ height: "100%", width: "100%" }}>
            {combinedList.map((item) =>
              "idPreCandidate" in item ? (
                <PreCandidateCard
                  key={item.idPreCandidate}
                  next={next}
                  prev={prev}
                  position={position}
                  preCandidate={item as PreCandidateInterface}
                  user={user}
                />
              ) : (
                <CandidateCard
                  key={item.id}
                  candidate={item as Candidate}
                  user={user}
                  position={position}
                  home
                  prev={prev}
                  next={next}
                />
              )
            )}
          </Carousel>

          {/* Indicador de posición */}
          {totalCount > 1 && (
            <div
              style={{
                width: 60,
                height: 35,
                position: "absolute",
                top: "3%",
                right: "3%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "1px solid #00000040",
                borderRadius: 16,
                backgroundColor: "#6052A3",
                boxShadow: "0px 0px 10px #00000016",
              }}
            >
              <Text style={{ textAlign: "center", fontSize: 23, color: "white" }}>
                {currentIndex + 1}/{totalCount}
              </Text>
            </div>
          )}
        </>
      ) : (
        <SourcingLoading dictionary={dictionary} />
      )}
    </Content>
  );
};

export default CandidateList;
